// Popover
import 'bootstrap/dist/css/bootstrap.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import { PopoverBody, PopoverHeader } from 'react-bootstrap';

function ViewAsPrimerJson(props: any) {
    const { primer, children } = props;
    return <>
        <OverlayTrigger placement="top" trigger={["hover", "focus"]}
            overlay={(
                <Popover>
                    <PopoverHeader>
                    <strong>{primer.type}</strong></PopoverHeader> 
                    <PopoverBody>
                        <pre>
                            {JSON.stringify(primer, null, 2)}
                        </pre>
                    </PopoverBody>
                </Popover>
            )}>
                {children}
        </OverlayTrigger>
    </>;
}

export default ViewAsPrimerJson;