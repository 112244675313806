import { useEffect, useState } from 'react';
import './Toast.scss';

function Toast(props: any) {
    const { message } = props;

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (message) {
            setShow(true);
            setTimeout(() => {
                setShow(false);
            }, 3000);
        }
    }, [message]);

    return <>
        {show && <>
            <div onClick={() => setShow(false)} className="alert alert-success">
                {message}
                <button type="button" className="btn-close ms-3 btn-sm" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </>}
    </>;
}

export default Toast;