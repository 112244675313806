function Loading(props: any) {
    const color = props.color || 'text-primary';
    const parent = props.parent || 'page';

    let marginEnd: string = parent === 'button' ? 'me-2' : '';

    return <>
        {parent === 'button' && <>
            <div className={`spinner-border spinner-border-sm ${color} ${marginEnd}`} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </>}

        {parent === 'page' && <>
            <div className="text-center">
                <div className={`spinner-border ${color}`} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </>}
    </>;
}

export default Loading;