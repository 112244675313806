import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";


function Help() {
    return <>
        <Navbar />

        <div className="container">
            <strong>v1.3.0</strong>
            <ul>
                <li>Fixed upload FASTA and Primersets</li>
                <li>Added Tm and Hairpin Tm to popover</li>
            </ul>

            <br/>

            <strong>v1.2.0-f</strong>
            <ul>
                <li>Fixed base positions to show large numbers</li>
                <li>Primers and Sequences are now components used across views</li>
                <li>Database is now hosted on Digital Ocean Auto-Managed Database (was on an Digital Ocean Droplet Self-Managed)</li>
                <li>API is now deployed to Firebase Functions (it was NodeJS on a Digital Ocean Droplet)</li>
                <li>Fixed viewer grid height</li>
            </ul>

            <br/>

            <strong>v1.0.0</strong>
            <ul>
                <li>Remove primersets (from Primersets section) won't remove its primers from database</li>
                <li>Remove primers (from Primersets section) won't remove the primer from database. It will just detach the primer from the primerset.</li>
                <li>Remove a primer (from Primers section) will remove it from database</li>
                <li>In Viewer, hover primer to see its details</li>
                <li>In Management &gt; Primers section, hover primer type to see its details</li>
                <li>You can only remove primers that are not included in any primersets</li>
            </ul>
        </div>

        <Footer />
    </>;
}

export default Help;