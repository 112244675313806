import { NavLink } from "react-router-dom";

function Navbar() {
    return <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary mb-4 js-navbar">
            <div className="container">
                {/*
                <a className="navbar-brand" href="#">Primer Viewer</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                */}
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/viewer">Primersets</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/sequencesComparator">Sequences</NavLink>
                        </li>
                        {/*
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/multiType">Multi Type</NavLink>
                        </li>
                        */}
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/management">Management</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/help">Help</NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink onClick={() => localStorage.removeItem('user')} className="nav-link" to="/">Exit</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>;
}

export default Navbar;