import { useState } from "react";
import { API } from "../../helpers";
import Loading from "../../components/Loading/Loading";

function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        setLoading(true);
        e.preventDefault();
        await fetch(`${API}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        })
        .then((res: any) => {
            if (!res.ok) throw new Error(res.status);
            return res.json();
        })
        .then((data: any) => {
            localStorage.setItem('user', JSON.stringify(data.user));
            setLoading(false);
            window.location.href = '/viewer';
        }).catch((err: any) => {
            if (err.message === '404') {
                setError('Invalid Credentials');
            }
            setLoading(false);
        });
    }

    return (
        <div className="container mt-4">
            <div className="row justify-content-center">
                <div className="col-12 col-md-4">

                    <div className="card shadow border-0">
                        <div className="card-body">
                            <div className="mb-4">
                                <strong>Login</strong>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-2">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        disabled={loading}
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        disabled={loading}
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                {error && <div className="alert alert-danger mt-3 mb-3" role="alert">{error}</div>}

                                <button
                                    disabled={!email || !password || loading}
                                    type="submit" className="btn btn-primary">
                                        {loading && <Loading parent="button" color="text-white" />}
                                        Submit
                                    </button>
                            </form>
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <small className="text-muted">v1.3.0</small>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Login;