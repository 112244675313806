import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Viewer from './views/Viewer/Viewer';
import Management from './views/Management/Management';
import Viewer20 from './views/Viewer20/Viewer20';
import Login from './views/Login/Login';
import { useEffect, useState } from 'react';
import Help from './views/Help/Help';
import SinglePrimer from './views/SinglePrimer/SinglePrimer';
import SequencesComparator from './views/SequencesComparator/SequencesComparator';
import MultipleComparison from './views/MultipleComparison/MultipleComparison';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const user = localStorage.getItem('user');
    (user) ? setIsLoggedIn(true) : setIsLoggedIn(false);
  }, []);

  return <>
    <Router>
      <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/viewer" element={isLoggedIn ? <Viewer /> : <Login />} />
          <Route path="/sequencesComparator" element={isLoggedIn ? <SequencesComparator /> : <Login />} />
          <Route path="/multiType" element={isLoggedIn ? <MultipleComparison /> : <Login />} />
          <Route path="/management" element={isLoggedIn ? <Management /> : <Login />} />
          <Route path="/help" element={isLoggedIn ? <Help /> : <Login />} />
          <Route path="/primer/:id" element={isLoggedIn ? <SinglePrimer /> : <Login />} />
          <Route path="/viewer20" element={isLoggedIn ? <Viewer20 /> : <Login />} />
      </Routes>
    </Router>
  </>;
}

export default App;
