import React from "react";

interface SequenceProps {
    keyId: string;
    sequence: string;
    showPositions?: boolean;
    showAllBases?: boolean;
    // mutations?: number[];
    isReference?: boolean;
    referenceSequence?: string;
}

function Sequence(props: SequenceProps): any {
    const {
        keyId,
        sequence,
        showPositions,
        showAllBases,
        // mutations,
        referenceSequence
    } = props;

    return (
        <div key={keyId} className={`${keyId} sequence`} style={{ display: 'block' }}>
            <ul className="list-unstyled">
                {sequence.split('').map((base: string, i: number) => {
                    let bgColor: string = 'transparent';
                    let fgColor: string = 'black';
                    let hasMutation: boolean = false;

                    // Used only for the sequences comparator
                    if (referenceSequence && referenceSequence[i].toUpperCase() !== base.toUpperCase()) {
                        bgColor = 'red';
                        fgColor = 'white';
                        hasMutation = true;
                    }

                    return <span key={`${keyId}-base-${i}`} className="base"
                        style={{ borderLeft: (i === 0) ? 'none' : '' }}>

                            {showPositions && <>
                                <div className="position">
                                    <small className="rotate">{i+1}</small>
                                </div>
                            </>}

                            <span
                              style={{
                                backgroundColor: bgColor,
                                color: fgColor, }}>
                                  {(referenceSequence === sequence || hasMutation || showAllBases) ? base.toUpperCase() : '.'}
                            </span>

                            <div className="vertical-separador"></div>
                    </span>
                })}
            </ul>
        </div>
    );
}

export default Sequence;