import {
    BASE_WIDTH,
    reversedPrimers,
    unusedPrimers,
} from "../../helpers";
import CustomPopover from "../Popover/Popover";

interface PrimerProps {
    keyId: string;
    primer: {
        type: string;
        sequence: string;
        pos5: number;
        pos3: number;
        len: number;
        primer_raw: {
            Tm: string;
            hairpin: {
                Tm: number
            }
        };
    };
    showPositions?: boolean;
    mutations?: number[];
    backgroundColor?: string;
}

const parseTwoDigits = (value: number): string => {
    const notNullValue: number = !value ? 0 : value;
    return parseFloat(notNullValue.toString()).toFixed(2)
}

function Primer(props: PrimerProps): any {
    const {
        keyId,
        primer,
        showPositions,
        mutations,
        backgroundColor,
    } = props;

    /**
     * Some specific primers types are reversed
     * So we need to reverse the sequence
     */
    const isPrimerReversed: boolean = reversedPrimers.includes(primer.type.toUpperCase()) ? true : false;
    const primerDirection: any = isPrimerReversed ? primer.sequence.split('').reverse().join('') : primer.sequence;

    const _showAlertEmptyPositions = () => {
        if (+primer.pos3 === 0 || +primer.pos5 === 0) {
            return <i className="fas fa-exclamation-triangle text-danger ms-2"></i>;
        }
        return null;
    }

    const isUnusedPrimer: string = unusedPrimers.includes(primer.type.toUpperCase()) ? 'opacity-30' : '';
    let counter: number = (primer.pos5 - 1) || 0;

    const primerTypeHeader: string = `${primer.type} ${isPrimerReversed === true ? '<=' : ''}`;

    const _calcPercentageMutations = (): number => {
        if (!mutations || mutations?.length === 0) {
            return 0;
        }
        return +((mutations.length * 100) / primerDirection.length).toFixed(2);
    }

    const popoverContent: any = `
        <strong>Length:</strong> ${primer.sequence.length}<br />
        <strong>3'pos:</strong> ${primer.pos3}<br />
        <strong>5'pos:</strong> ${primer.pos5}<br />
        <strong>Tm:</strong> ${parseTwoDigits(parseFloat(primer.primer_raw.Tm))}<br />
        <strong>HairPin Tm:</strong> ${parseTwoDigits(primer.primer_raw.hairpin.Tm)}<br /><br />
        <strong>Mutations:</strong> ${mutations?.length} (${_calcPercentageMutations()}%)<br />
    `;

    return <div key={`${keyId}-${Math.random()}`}
            className={`${keyId} primer ${isUnusedPrimer}`}
            style={{
                backgroundColor: backgroundColor,
                marginLeft: `${((+primer.pos5 || 0) * BASE_WIDTH) - BASE_WIDTH}px` }}>

            <CustomPopover header={primerTypeHeader} body={popoverContent}>
                <div>
                    <div className="header">
                        <strong>
                            {primerTypeHeader} {_showAlertEmptyPositions()}
                        </strong>
                    </div>
                    <ul className={`list-unstyled`}>
                        {primerDirection.split('').map((base: string, i: number) => {
                            let bgColor: string = backgroundColor || 'transparent';
                            let fgColor: string = 'black';

                            if (mutations && mutations.length && mutations.includes(i)) {
                                bgColor = 'red';
                                fgColor = 'white';
                            }

                            counter++;
                            const elementKey: string = `${keyId}-base-${base.toUpperCase()}-${i}`;

                            return <li key={elementKey} className={`${elementKey} base`}
                                style={{ backgroundColor: bgColor, color: fgColor, }}>

                                {showPositions && (
                                    <div className="position">
                                        <small className="rotate">{counter}</small>
                                    </div>
                                )}
                                <span>{base.toUpperCase()}</span>
                            </li>
                        })}
                    </ul>
                </div>
            </CustomPopover>
        </div>
}

export default Primer;