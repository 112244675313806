const API: string = process.env.REACT_APP_API || '';

// These values should match the values in the variables.scss file
const BASE_WIDTH: number = 20;
const BASE_HEIGHT: number = 50;

const bases: any =        ['A','T','C','G','-'];
const basesReverse: any = ['T','A','G','C','-'];

const reversedPrimers: any = ['F1C', 'LF', 'B2', 'B3'];

// Not used anymore because the template generated by the
// Designer Tool is not up to date and still generates these
const unusedPrimers: any = ['FIP', 'BIP'];

const getRandomColor = (base: number = Math.random()): string => {
    const opacity: number = 50;
    const colors: string[] = [
        '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    ];
    return `${colors[Math.floor(base * colors.length)]}${opacity}`;
};

export {
    API,
    BASE_WIDTH,
    BASE_HEIGHT,
    bases,
    basesReverse,
    reversedPrimers,
    unusedPrimers,
    getRandomColor,
};