function Footer() {
    return <>
        <div className="container pt-5 mt-5 mb-5">
            <small className="text-muted">
                Primer Viewer tool &middot; v1.3.0-f
            </small>
        </div>
    </>;
}

export default Footer;