import { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import Footer from "../../components/Footer";
import { API } from "../../helpers";
import { Link, useParams } from "react-router-dom";

function SinglePrimer() {
    const [primer, setPrimer] = useState(null);
    const [primerId, setPrimerId] = useState(0);

    const urlParams = useParams();

    useEffect(() => {
        const primerId: number = urlParams.id ? +urlParams.id : 0;
        setPrimerId(primerId);

        async function _getPrimerFromDB(): Promise<void> {
            if (primerId !== 0) {
                await fetch(`${API}/primers/${primerId.toString()}`)
                .then((response: any) => response.json())
                .then((data: any) => {
                    setPrimer(data);
                });
            }
        }

        _getPrimerFromDB();

    }, [primerId, urlParams.id]);

    return <>
        <Navbar />

        {primer && <>
            <div className="container">
                <div className="mb-3">
                    <Link to={'/management'} className="btn btn-link btn-sm"><i className="fas fa-arrow-left me-2"></i> back</Link>
                </div>
                <pre>
                    {JSON.stringify(primer, null, 2)}
                </pre>
            </div>
        </>}

        <Footer />
    </>;
}

export default SinglePrimer;