import React, { useEffect, useState } from 'react';
import './Viewer20.scss';

const API: string = 'http://localhost:4201';

/*
FUNCIONANDO COM O BANCO DE DADOS MySQL
MAS ESTÁ UM PASSO ALÉM DO QUE PRECISAMOS AGORA
Testando os highlights dos primers nas sequências selecionadas
Ainda tem muita coisa fixa no código, mas já dá pra ter uma ideia do que seria
A versão atual do componente Viewer é exatamente o que precisamos agora, então esta versão 2.0
Foi salva para eu consultar depois, quando precisarmos de mais funcionalidades
*/

const primers: any[] = [
  {
    type: 'F2',
    sequence: 'TCAAAAGAGAAATAACGTTCCA',
    '5\'pos': 308,
    '3\'pos': 329,
    len: 22,
    Tm: 62.82650834798426,
    '5\'dG': 0,
    '3\'dG': -21.80045356586622,
    GCrate: 0.3181818181818182,
    hairpin: {
      Tm: 0
    },
  },
  {
    type: 'F3',
    sequence: 'AATGCCCTAAATGGGAATGGG',
    '5\'pos': 244,
    '3\'pos': 264,
    len: 21,
    Tm: 60.07066991710292,
    '5\'dG': 0,
    '3\'dG': -21.157635062732822,
    GCrate: 0.38095238095238093,
    hairpin: {
      Tm: 0
    },
  },
];

function Viewer20() {
  const [fastasNamesList, setFastasNamesList] = useState<any>([]);
  const [primersList, setPrimersList] = useState<any>([]);
  const [fastaContent, setFastaContent] = useState<any>(null);
  const [highlightedPrimers, setHighlightedPrimers] = useState<any>([]);

  async function _getFastasListFromDB(): Promise<void> {
    await fetch(`${API}/fastas`)
      .then((response: any) => response.json())
      .then((data: any) => {
        setFastasNamesList(data.fastas);
      });
  }

  async function _getFastaFileById(id: any): Promise<void> {
    setHighlightedPrimers([]);
    await fetch(`${API}/fastas/${id}`)
      .then((response: any) => response.json())
      .then((data: any) => {
        console.log(data)
        setFastaContent(data);
      });
  }

  useEffect(() => {
    _getFastasListFromDB();
    setPrimersList(primers);
  }, []);

  function _getLinearSequence(sequence: string): string {
    let content = sequence.trim().replace(`\n`, ``);
    content = content.split('').map((nucleotide: string, index: number) => {
      return nucleotide.toUpperCase();
    }).join('');

    if (highlightedPrimers.length > 0) {
      highlightedPrimers.forEach((primer: string) => {
        const primerItem: any = primersList.filter((item: any) => item.sequence === primer)[0];
        const primerName: string = primerItem ? primerItem.type : '';
        content = content.replace(primer, `<span class='highlight'><span class='sequence-primername'>${primerName}</span>${primer.toUpperCase()}</span>`);
      });
    }
    return content;
  }

  function _removeFromHightlightedPrimers(primer: string): void {
    setHighlightedPrimers(highlightedPrimers.filter((item: any) => item !== primer));
  }

  function _clicou(item: any): void {
    console.log('clicou', item);
  }

  function _parseFasta(content: string): void {
    const fasta: any = {
      fasta: [],
      str: '',
    };

    const fastaList: any = content.split('>');
    fastaList.shift();
    fastaList.forEach((item: any) => {
      const description: string = item.split('\n')[0];
      const sequence: string = item.split('\n').slice(1).join('');
      fasta.fasta.push({
        description,
        sequence,
      });
      fasta.str += sequence;
    });

    setFastaContent(fasta);
  }

  return <>
    <p>
      <select onChange={(e) => _getFastaFileById(e.target.value)}>
        <option value="">Select a FASTA file</option>
        {fastasNamesList.length > 0 && fastasNamesList.map((fasta: any) => {
          return <option key={fasta.idfastas} value={fasta.idfastas}>{fasta.name}</option>
        })}
      </select>
    </p>

    <p>
      Or paste a FASTA content:<br/>
      <textarea cols={150} rows={20} onChange={(e: any) => _parseFasta(e.target.value)}></textarea>
    </p>

    Hightlight primer:
    <select disabled={!fastaContent?.fasta} onChange={(e) => (e.target.value !== '' && !highlightedPrimers.includes(e.target.value)) && setHighlightedPrimers([...highlightedPrimers, e.target.value])}>
      <option value="">Select a primer from a list</option>
      {
        primersList.length > 0 && primersList.map((primer: any, index: number) => {
          return <option key={`primer-${index}`} disabled={highlightedPrimers.includes(primer.sequence)} value={primer.sequence}>{primer.type} - {primer.sequence}</option>;
        })
      }
    </select>

    <br /><br />

    Or type a primer sequence:
    <input type="text" disabled={!fastaContent?.fasta} className='uppercase' onChange={(e) => (e.target.value !== '' && !highlightedPrimers.includes(e.target.value)) && setHighlightedPrimers([...highlightedPrimers, e.target.value])} />

    <br /><br />

    <button type='button' disabled={highlightedPrimers.length === 0} onClick={() => setHighlightedPrimers([])}>Clear highlighted primers</button>

    {fastaContent && fastaContent.fasta && 
      <React.Fragment>
        <p>
          {fastaContent && fastaContent.fasta.map((item: any, index: number) => {
            return <React.Fragment key={`item-${index}`}>
              <br />
              <small>seq</small>
              <br />
              <span className='sequence' onClick={() => _clicou(item)} dangerouslySetInnerHTML={{ __html: _getLinearSequence(item.sequence) }}></span>
            </React.Fragment>;
          })}
        </p>

        <br/>

        {highlightedPrimers.length > 0 && <>
          <p>
            <strong>Highlighted primers:</strong>
          </p>
          <ul>
            {highlightedPrimers.map((primer: string, index: number) => {
              return <li key={`primer-${index}`}>
                <>
                  {primersList.find((item: any) => item.sequence === primer) && 
                    <strong>{primersList.find((item: any) => item.sequence === primer).type} - </strong>}
                </>
                {primer} <button type='button' onClick={() => _removeFromHightlightedPrimers(primer)}>[x]</button>
              </li>;
            })}
          </ul>
        </>}

        <br/><br/>

        <strong>File preview:</strong><br/>
        <pre>{fastaContent && fastaContent.str}</pre>
      </React.Fragment>}
  </>;
}

export default Viewer20;
