import React, { useEffect, useState } from 'react';
import './Viewer.scss';
import * as helpers from '../../helpers';
import Navbar from '../../components/Navbar';
import { AuthInterceptor } from '../../AuthInterceptor';
import Loading from '../../components/Loading/Loading';
import {
  API,
  bases,
  basesReverse,
  reversedPrimers,
} from '../../helpers';
import Primer from '../../components/Primer/Primer';
import Sequence from '../../components/Sequence/Sequence';

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  const [listSequences, setListSequences] = useState<any>([]);
  const [listPrimersets, setListPrimersets] = useState<any>([]);
  const [selectedSequence, setSelectedSequence] = useState<any>(null);
  const [primersetsInView, setPrimersetsInView] = useState<any>([]);
  const [primersetListValue, setPrimersetListValue] = useState<any>('');
  const [primersetColors, setPrimersetColors] = useState<any>([]);

  async function _getSequencesListFromDB(): Promise<void> {
    await fetch(`${API}/sequences`)
      .then((response: any) => response.json())
      .then((data: any) => {
        setListSequences(data.sequences);
        setLoading(false);
      });
  }

  async function _getPrimersetsListFromDB(): Promise<void> {
    await fetch(`${API}/primersets`)
      .then((response: any) => response.json())
      .then((data: any) => {
        setListPrimersets(data.primersets);
        setLoading(false);
      });
  }

  function _removePrimersetFromView(primerset: any): void {
    setPrimersetsInView(primersetsInView.filter((item: any) => item.id !== primerset.id));
  }

  useEffect(() => {
    AuthInterceptor();
    setLoading(true);
    _getSequencesListFromDB();
    _getPrimersetsListFromDB();
  }, []);

  useEffect(() => {
    const colors: any = [];
    primersetsInView.forEach((item: any) => {
      colors.push({ primersetId: item.id, color: helpers.getRandomColor(Math.random()) });
    });
    setPrimersetColors(colors);
  }, [primersetsInView]);

  return <>
    <Navbar />

    {loading && <Loading />}

    {!loading && <>

      <div className='container js-main-container'>
        {listSequences && <>
          <div className="form-group mb-3">
            <label>Select a sequence to display</label>
            <select
              className='form-control'
              onChange={(e: any) => setSelectedSequence(listSequences.find((sequence: any) => sequence.id === +e.target.value))}
              disabled={listSequences.length === 0 || loading}>
                <option value=''>{listSequences.length === 0 ? 'No sequences to select' : '-'}</option>
                {listSequences.map((sequence: any) => {
                  return <option key={`Sequence-option-${sequence.id}`} value={sequence.id}>{sequence.description}</option>
                })}
            </select>
          </div>

          {selectedSequence && <>
            <div className="form-group mb-3">
              <div className="row">
                <div className="col-8">
                  <label>Select a primerset to add it to the sequence below</label>
                  <select
                    disabled={!selectedSequence || loading}
                    value={primersetListValue}
                    className='form-control'
                    onChange={(e: any) => {
                      e.target.value && setPrimersetsInView([ ...primersetsInView, listPrimersets.find((item: any) => item.id === +e.target.value) ])
                      e.target.value && setPrimersetListValue('');
                    }}>
                      <option value=''>-</option>

                      <optgroup label="Matched Primersets" key={'matched-primersets-group'}>
                        {listPrimersets.map((primerset: any, i: number) => {
                          return (+primerset.match_sequence_id === +selectedSequence.id) && 
                            <option
                              key={`Primersets-matched-${primerset.id}`}
                              disabled={primersetsInView.find((item: any) => item.id === +primerset.id)}
                              value={primerset.id}>{primerset.name} ({primerset.primers.length} primers: {primerset.primers.map((item: any) => item.type).join(', ')})
                            </option>
                        })}
                      </optgroup>
                      <optgroup label="Unmatched Primersets" key={'unmatched-primersets-group'}>
                        {listPrimersets.map((primerset: any, i: number) => {
                          return (+primerset.match_sequence_id !== +selectedSequence.id) && 
                            <option
                              key={`Primersets-unmatched-${primerset.id}`}
                              disabled={primersetsInView.find((item: any) => item.id === +primerset.id)}
                              value={primerset.id}>{primerset.name} ({primerset.primers.length} primers: {primerset.primers.map((item: any) => item.type).join(', ')})
                            </option>
                        })}
                      </optgroup>

                  </select>
                </div>
              </div>
            </div>

            {primersetsInView.length > 0 && <>
              <div className="mb-5">
                <small className="text-muted">
                  {primersetsInView.length} primersets in View
                  <ul className='list-unstyled mt-1'>
                    {primersetsInView.map((item: any, i: number) => {
                      return <li key={`Primersets-in-view-Primerset-${item.id}-li-${i}`} className='ms-3'>
                        {primersetColors.find((color: any) => color.primersetId === item.id) && <span className="badge rounded-pill me-1" style={{ backgroundColor: primersetColors.find((color: any) => +color.primersetId === +item.id).color }}>&nbsp;</span>}
                        {item.name} ({item.primers.length} primers) <button onClick={() => _removePrimersetFromView(item)} className="btn btn-link btn-sm p-0 m-0">(remove from view)</button>
                      </li>
                    })}
                  </ul>
                </small>
              </div>
            </>}
          </>}

          {selectedSequence && <>
            <div className="mb-3">
              <strong>View</strong>
            </div>

            <small className='text-muted'>
              <p><strong>Sequence:</strong> {selectedSequence.description}</p>
            </small>
          </>}
        </>}
      </div>{/* /.container */}

      <div className="container-fluid mb-5">
        {listSequences && <>

          <div className="viewer-grid js-viewer-grid">

            {selectedSequence && <>
              <Sequence
                sequence={selectedSequence.sequence}
                keyId={`Sequence-${selectedSequence.id}`}
                showPositions={true}
                showAllBases={true} />

              {primersetsInView.length > 0 && <>
                {primersetsInView.map((primerset: any, i: number) => {
                  return <div className={`Primersets-in-view-${primerset.id}-${i} primerset`} key={`Primersets-in-view-${primerset.id}-${i}`}>
                    {primerset.primers.map((primer: any) => {

                      // Finding mutations in primer, based on selectedSequence
                      /**
                       * For regular primers is just compare the templateSequence[i] and primerSequence[i]
                       *  A C T G  <- templateSequence
                       *  | | | |
                       *  A C T G  <- primerSequence
                       * 
                       * For reverse primers is compare the templateSequence[i] and complimentaryBase(reversePrimerSequence[i])
                       *  T G C A  <- primerSequence
                       *  A C G T  <- primerSequence.reverse() = reversePrimerSequence
                       * 
                       *  A C T G  <- templateSequence
                       *  | | | |
                       *  T G A C  <- complimentaryBase(reversePrimerSequence[i])
                       */
                      let mutations: number[] = [];
                      const correspondingSequence: string = selectedSequence.sequence.slice(+primer.pos5 -1, +primer.pos3);
                      for (let i = 0; i < correspondingSequence.length; i++) {

                        const isPrimerReversed: boolean = reversedPrimers.includes(primer.type.toUpperCase()) ? true : false;
                        const sequenceBase: string = correspondingSequence[i].toUpperCase();
                        const primerSequenceReverse: string = primer.sequence.split('').reverse().join('');
                        const primerBase: string = isPrimerReversed ? primerSequenceReverse[i].toUpperCase() : primer.sequence[i].toUpperCase();
                        const primerBaseComplimentary: string = basesReverse[bases.indexOf(primerBase)];

                        if (isPrimerReversed) {
                          if (sequenceBase !== primerBase && sequenceBase !== primerBaseComplimentary) {
                            mutations.push(i);
                          }
                        } else {
                          if (sequenceBase !== primerBase) {
                            mutations.push(i);
                          }
                        }
                      }

                      // Primer component will reverse primer.sequence automatically if needed.
                      // Don't need to send isReversed prop or reverse the primer.sequence manually here

                      return (
                        <React.Fragment key={`Primer-${primer.type}-group-as-primerset-${primerset.id}`}>
                          <Primer
                            primer={primer}
                            keyId={`Primer-${primer.type}-primerset-${primerset.id}`}
                            mutations={mutations}
                            showPositions={false}
                            backgroundColor={primersetColors.find((color: any) => color.primersetId === primerset.id)?.color || null} />
                        </React.Fragment>
                      );
                    })}
                  </div>
                })}
              </>}
            </>}

          </div>{/* /.viewer-grid */}

        </>}
      </div>{/* /.container-fluid */}

    </>}
  </>;
}

export default App;
