import fetchIntercept from 'fetch-intercept';

export const AuthInterceptor = () => {
    fetchIntercept.register({
    request: function (url: any, config: any) {
        let newConfig = config;

        newConfig = {
            ...config,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
            },
        };

        const userStorage: any = localStorage.getItem('user');
        if (userStorage) {
            const user: any = JSON.parse(userStorage);
            newConfig = {
                ...newConfig,
                headers: {
                    ...newConfig.headers,
                    Authorization: `Bearer ${user.accessToken}`,
                },
            };
        }

        if (config && (config.body instanceof FormData)) {
            delete newConfig.headers['Content-Type'];
        }

        return [url, newConfig];
    },
    requestError: function (error: any) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },
    response: function (response: any) {
        // Modify the reponse object
        return response;
    },
    responseError: function (error: any) {
        // Handle an fetch error
        return Promise.reject(error);
    },
  });
};