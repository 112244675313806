import { useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar';
import { API, reversedPrimers } from '../../helpers';
import React from 'react';
import Footer from '../../components/Footer';
import { AuthInterceptor } from '../../AuthInterceptor';
import Toast from '../../components/Toast/Toast';
import ViewPrimerAsJson from '../../components/ViewPrimerAsJson/ViewPrimerAsJson';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import TextWithTooltip from '../../components/TextWithTooltip/TextWithTooltip';

function Management() {
  const [loading, setLoading] = useState<boolean>(false);
  const [listSequences, setListSequences] = useState<any>([]);
  const [listPrimersets, setListPrimersets] = useState<any>([]);
  const [listPrimers, setListPrimers] = useState<any>([]);

  const [selectedPrimer, setSelectedPrimer] = useState<any>('');

  const [fileToUpload, setFileToUpload] = useState<any>(null);
  const [fileToUploadExtension, setFileToUploadExtension] = useState<any>(null);

  const [toastMessage, setToastMessage] = useState<any>(null);

  const inputFile = useRef<any>(null);

  async function _getSequencesListFromDB(): Promise<void> {
    await fetch(`${API}/sequences`)
      .then((response: any) => response.json())
      .then((data: any) => {
        setListSequences(data.sequences);
        setLoading(false);
      });
  }

  async function _getPrimersetsListFromDB(): Promise<void> {
    await fetch(`${API}/primersets`)
      .then((response: any) => response.json())
      .then((data: any) => {
        setListPrimersets(data.primersets);
        setLoading(false);
      });
  }

  async function _getPrimersListFromDB(): Promise<void> {
    await fetch(`${API}/primers`)
      .then((response: any) => response.json())
      .then((data: any) => {
        setListPrimers(data.primers);
        setLoading(false);
      });
  }

  async function _deletePrimerFromPrimerset(primersetId: number, primerId: number): Promise<void> {
    const primerType: string = listPrimers.find((primer: any) => primer.id === primerId)?.type || "";
    const primersetName: string = listPrimersets.find((primerset: any) => primerset.id === primersetId)?.name || "";
    if (window.confirm(`Are you sure you want to remove primer "${primerType}" from primerset "${primersetName}"?\nThe primer "${primerType}" won't be deleted from database`) === false) {
      return;
    }

    await fetch(`${API}/primersFromPrimerset`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        primersetId: primersetId,
        primerId: primerId
      })
    }).then((response: any) => response.json())
    .then(() => {
      // Remove primer from this primerset
      listPrimersets.find((primerset: any) => {
        if (primerset.id === primersetId) {
          primerset.primers = primerset.primers.filter((primer: any) => primer.id !== primerId);
        }
        return null;
      });
      setListPrimersets([...listPrimersets]);
      setToastMessage(`Primer "${primerType}" removed from primerset "${primersetName}"`);
    });
  }

  async function _deletePrimer(primerId: number): Promise<void> {
    const primerType: string = listPrimers.find((primer: any) => primer.id === primerId)?.type || "";
    if (window.confirm(`Are you sure you want to delete primer "${primerType}"?\nThis action cannot be undone.`) === false) {
      return;
    }

    await fetch(`${API}/primers`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        primerId: primerId
      })
    }).then((response: any) => response.json())
    .then(() => {
      setListPrimers(listPrimers.filter((primer: any) => primer.id !== primerId));
      setToastMessage(`Primer "${primerType}" deleted`);
    });
  }

  async function _addPrimerIntoPrimerset(primersetId: number): Promise<void> {
    if (selectedPrimer === null) {
      return;
    }

    await fetch(`${API}/primers/addToPrimerset`, {
      method: 'POST',
      body: JSON.stringify({
        primersetId: primersetId,
        primerId: selectedPrimer,
      })
    }).then((response: any) => response.json())
    .then(() => {
      // Add primer into this primerset
      listPrimersets.find((primerset: any) => {
        if (+primerset.id === +primersetId) {
          listPrimers.find((primer: any) => {
            if (+primer.id === +selectedPrimer) {
              primerset.primers.push(primer);
            }
            return null;
          });
        }
        return null;
      });
      setListPrimersets([...listPrimersets]);
      setSelectedPrimer('');

      const primerType: string = listPrimers.find((primer: any) => primer.id === +selectedPrimer)?.type || "";
      const primersetName: string = listPrimersets.find((primerset: any) => primerset.id === primersetId)?.name || "";
      setToastMessage(`Primer "${primerType}" added to primerset "${primersetName}"`);
    });
  }

  async function _deleteSequence(sequenceId: number): Promise<void> {
    if (window.confirm(`Are you sure you want to delete this sequence?\nThis action cannot be undone.`) === false) {
      return;
    }

    await fetch(`${API}/sequences`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sequenceId: sequenceId
      })
    }).then((response: any) => response.json())
    .then(() => {
      setListSequences(listSequences.filter((sequence: any) => sequence.id !== sequenceId));
      setToastMessage("Sequence deleted");
    });
  }

  async function _uploadFastaFile(): Promise<void> {
    const file = fileToUpload;
    const formData = new FormData();
    const requestOptions = {  method: 'POST', body: formData,};
    
    formData.append('file', file[0], file.name);

    await fetch(`${API}/sequences/uploadFastaFile`, requestOptions)
    .then((response: any) => response.json())
    .then((data: any) => {
      setListSequences([...listSequences, ...data.newSequences]);
      setFileToUpload(null);
      inputFile.current.value = null;
    });
  }

  async function _uploadPrimersetFile(): Promise<void> {
    const file = fileToUpload
    const formData = new FormData();
    const requestOptions = {  method: 'POST', body: formData,};
    
    formData.append('file', file[0], file.name);

    await fetch(`${API}/primersets`, requestOptions)
    .then((response: any) => response.json())
    .then((data: any) => {
      setListPrimersets([...listPrimersets, data.primerset]);
      setFileToUpload(null);
      inputFile.current.value = null;
      _getPrimersListFromDB();
    });
  }

  async function _deletePrimerset(primersetId: number): Promise<void> {
    const countPrimersInPrimerset = listPrimersets.find((primerset: any) => primerset.id === primersetId)?.primers.length || 0;
    const primersetName: string = listPrimersets.find((primerset: any) => primerset.id === primersetId)?.name || "";
    if (window.confirm(`Are you sure you want to delete primerset "${primersetName}"?\nIts ${countPrimersInPrimerset} primers won't be deleted`) === false) {
      return;
    }

    await fetch(`${API}/primersets/${primersetId}`, {
      method: 'DELETE',
    }).then((response: any) => response.json())
    .then(() => {
      setListPrimersets(listPrimersets.filter((primerset: any) => primerset.id !== primersetId));
      setToastMessage(`Primerset "${primersetName}" deleted. (Its ${countPrimersInPrimerset} primers not deleted)`);
    });
  }

  async function _changePrimersetMatchSequence(primersetId: number, e: any): Promise<void> {
    const sequenceId: number = e.target.value;
    await fetch(`${API}/primersets/sequenceMatch`, {
      method: 'POST',
      body: JSON.stringify({
        sequenceId,
        primersetId,
      })
    }).then((response: any) => response.json())
    .then(() => {
      // Update primerset match sequence
      listPrimersets.find((primerset: any) => {
        if (+primerset.id === +primersetId) {
          primerset.match_sequence_id = sequenceId;
        }
        return null;
      });
      setToastMessage("Matching sequence changed");
    });
  }

  function randomKeyIndex(): number { 
    return Math.floor(Math.random() * 1000);
  }

  useEffect(() => {
    AuthInterceptor();
    setLoading(true);
    _getSequencesListFromDB();
    _getPrimersetsListFromDB();
    _getPrimersListFromDB();
  }, []);

  return <>
    <Navbar />
    <Toast message={toastMessage} />

    {loading && <Loading />}

    {!loading && <>

      <div className="container">
        <div className="mb-2">
          Select a new FASTA file (.fasta) or a new primerset file (.json) to upload
        </div>
        <div className="row mb-5">
          <div className="col-4">
            <input
              ref={inputFile}
              onChange={(e: any) => {

                setFileToUpload(e.target.files);
                const fileName: string = e.target.files[0].name || "1.generic";
                const fileExtension: string = fileName.split('.').pop() || "";
                setFileToUploadExtension(fileExtension);
              }}
              type="file"
              className='form-control' />
          </div>
        </div>

        <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="sequences-tab" data-bs-toggle="tab" data-bs-target="#sequences-tab-pane" type="button" role="tab" aria-controls="sequences-tab-pane" aria-selected="true">Sequences <span className="text-muted">({listSequences.length})</span></button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="primersets-tab" data-bs-toggle="tab" data-bs-target="#primersets-tab-pane" type="button" role="tab" aria-controls="primersets-tab-pane" aria-selected="false">Primersets <span className="text-muted">({listPrimersets.length})</span></button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="primers-tab" data-bs-toggle="tab" data-bs-target="#primers-tab-pane" type="button" role="tab" aria-controls="primers-tab-pane" aria-selected="false">Primers <span className="text-muted">({listPrimers.length})</span></button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">

          {/* Sequences tab */}
          <div className="tab-pane show active" id="sequences-tab-pane" role="tabpanel" aria-labelledby="sequences-tab" tab-index="0">

            <div className="row mb-3 align-items-center">
              <div className="col-10">
                <strong>Sequences <span className="text-muted">({listSequences.length} items found)</span></strong>
              </div>
              <div className="col-2 text-end">
                <button
                  onClick={() => _uploadFastaFile()}
                  disabled={(fileToUpload === null || !['generic', 'fasta'].includes(fileToUploadExtension)) ? true : false}
                  type='button'
                  className='btn btn-primary'>
                    <i className="fas fa-file-upload me-2"></i> Upload as FASTA file
                </button>
              </div>
            </div>

            {listSequences.length > 0 && <>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th className='text-muted text-center'><small>#</small></th>
                    <th>FASTA</th>
                    <th>Description</th>
                    <th className='text-end'>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {listSequences.map((sequence: any, i: number) => {
                    return <React.Fragment key={`${sequence.id}-${randomKeyIndex()}`}>
                      <tr>
                        <td className='text-muted text-center'><small>{i+1}</small></td>
                        <td className='text-muted'>
                          <small>
                            <TextWithTooltip id={`tooltip-${sequence.id}`} tooltip={sequence.fasta_name}>
                              <div>
                                {sequence.fasta_name.substring(0, 20)}...
                              </div>
                            </TextWithTooltip>
                          </small>
                        </td>
                        <td>
                          <TextWithTooltip id={`tooltip-${sequence.id}`} tooltip={sequence.description}>
                            <div>{sequence.description.substring(0, 100)}...</div>
                          </TextWithTooltip>
                        </td>
                        <td className='text-end'>
                          <button onClick={() => _deleteSequence(sequence.id)} type='button' className='btn btn-link btn-sm p-0 m-0'>
                            <i className="fas fa-times text-danger me-2"></i> Remove
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  })}
                </tbody>
              </table>
            </>}

          </div>
          {/* /Sequences tab */}

          {/* Primerset tab */}
          <div className="tab-pane" id="primersets-tab-pane" role="tabpanel" aria-labelledby="primersets-tab" tab-index="0">

            <div className="row mb-3 align-items-center">
              <div className="col-10">
                <strong>Primersets <span className="text-muted">({listPrimersets.length} items found)</span></strong>
              </div>
              <div className="col-2 text-end">
                <button
                  onClick={() => _uploadPrimersetFile()}
                  disabled={(fileToUpload === null || !['generic', 'json'].includes(fileToUploadExtension)) ? true : false}
                  type='button'
                  className='btn btn-primary'>
                    <i className="fas fa-file-upload me-2"></i> Upload as Primerset
                </button>
              </div>
            </div>

            {listPrimersets.length > 0 && <>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th className='text-muted text-center'><small>#</small></th>
                    <th>Name</th>
                    <th style={{ width: '60%' }}>Primers</th>
                    <th>Sequence Match</th>
                  </tr>
                </thead>
                <tbody>
                  {listPrimersets.map((primerset: any, i: number) => {
                    return <React.Fragment key={`primerset-${primerset.id}-${randomKeyIndex()}`}>
                      <tr>
                        <td className='text-muted text-center'><small>{i+1}</small></td>
                        <td>
                          <div className="mb-2">
                            {primerset.name}
                          </div>
                          <button onClick={() => _deletePrimerset(primerset.id)} type='button' className='btn btn-link btn-sm p-0 m-0'>
                            <i className="fas fa-trash text-danger me-2"></i> remove
                          </button>
                        </td>
                        <td>
                          <div className="row mb-2">
                            <div className="col-2"><strong>Type</strong></div>
                            <div className="col-6"><strong>Sequence</strong></div>
                            <div className="col-4 text-end"><strong>Options</strong></div>
                          </div>

                          {primerset.primers.map((primer: any, j: number) => {
                            return <React.Fragment key={`primer-${j}-inside-primerset-${primerset.id}-${randomKeyIndex()}`}>
                              {/* {`${JSON.stringify(primer)}`} */}
                              <div className="row">
                                <div className={`col-2 ${(+primer.pos5 === 0 || +primer.pos3 === 0) ? 'text-danger' : ''}`}>
                                  {primer.type} {reversedPrimers.includes(primer.type.toUpperCase()) && <>&lt;=</>} {(+primer.pos5 === 0 || +primer.pos3 === 0) && <><i className="fas fa-exclamation-triangle ms-1 text-warning"></i></>}
                                </div>
                                <div className="col-6">{primer.sequence.toUpperCase().substr(0, 20)}...</div>
                                <div className="col-4 text-end">
                                  <button onClick={() => _deletePrimerFromPrimerset(primerset.id, primer.id)} type='button' className='btn btn-link btn-sm p-0 m-0'>
                                    <i className="fas fa-times text-danger me-2"></i>
                                  </button>
                                </div>
                              </div>
                            </React.Fragment>
                          })}

                          <div className="mt-2 mb-3">
                            <small className="text-muted">{primerset.primers.length} primers found</small>
                          </div>

                          <div className="row align-items-start">
                            <div className="col-10">
                              <select value={selectedPrimer} onChange={(e: any) => setSelectedPrimer(e.target.value)} className='form-select form-select-sm'>
                                <option value=''>Select a primer to add into {primerset.name}</option>
                                {listPrimers.map((primer: any, j: number) => {
                                  return <React.Fragment key={`primer-${j}-inside-primerset-${primerset.id}-${randomKeyIndex()}`}>
                                    <option
                                      disabled={primerset.primers.find((p: any) => p.id === primer.id) !== undefined ? true : false}
                                      value={primer.id}>
                                        {primer.type} - {primer.sequence.toUpperCase()}
                                    </option>
                                  </React.Fragment>
                                })}
                              </select>
                            </div>
                            <div className="col-2 text-end">
                              <button disabled={!selectedPrimer} onClick={() => _addPrimerIntoPrimerset(primerset.id)} type='button' className='btn btn-link btn-sm p-0 m-0'>
                                <i className="fas fa-plus text-success ms-2"></i> Add Primer
                              </button>
                            </div>
                          </div>

                        </td>
                        <td>
                          <div className="form-group">
                            <select
                              value={primerset.match_sequence_id}
                              onChange={(e: any) => _changePrimersetMatchSequence(primerset.id, e)}
                              className='form-control'>
                                <option value={0}>No match</option>
                                {listSequences.map((sequence: any, j: number) => {
                                  return <React.Fragment key={`sequence-${j}-inside-primerset-${primerset.id}-${randomKeyIndex()}`}>
                                    <option
                                      disabled={primerset.match_sequence_id === sequence.id ? true : false}
                                      value={sequence.id}>
                                        {sequence.description}
                                    </option>
                                  </React.Fragment>
                                })}
                            </select>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  })}
                </tbody>
              </table>
            </>}

          </div>
          {/* /Primerset tab */}

          {/* Primers tab */}
          <div className="tab-pane" id="primers-tab-pane" role="tabpanel" aria-labelledby="primers-tab" tab-index="0">

            <div className="mb-3">
              <strong>Primers <span className="text-muted">({listPrimers.length} items found)</span></strong>
            </div>

            {listPrimers.length > 0 && <>
              <table className='table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th className='text-muted text-center'><small>#</small></th>
                    <th>Type</th>
                    <th>Sequence</th>
                    <th>Length</th>
                    <th>5'pos</th>
                    <th>3'pos</th>
                    <th className='text-end'>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {listPrimers.map((primer: any, i: number) => {
                    return <React.Fragment key={`${primer.id}-${randomKeyIndex()}`}>
                      <tr>
                        <td className='text-muted text-center'><small>{i+1}</small></td>
                        <td>
                          <ViewPrimerAsJson primer={primer}>
                            <React.Fragment>
                              <Link to={`/primer/${primer.id}`}>
                                {primer.type} {reversedPrimers.includes(primer.type.toUpperCase()) && <>&lt;=</>}
                              </Link>
                              {primer.primerset_name && <>
                                <div className="mt-1">
                                  <small className="text-muted">Primerset: {primer.primerset_name}</small>
                                </div>
                              </>}
                            </React.Fragment>
                          </ViewPrimerAsJson>
                        </td>
                        <td>{primer.sequence}</td>
                        <td>{primer.len}</td>
                        <td className={+primer.pos5 === 0 ? 'bg-danger' : ''}>{primer.pos5 || '-'}</td>
                        <td className={+primer.pos3 === 0 ? 'bg-danger' : ''}>{primer.pos3 || '-'}</td>
                        <td className='text-end'>
                          <button disabled={
                            listPrimersets.find((primerset: any) => {
                              if (primerset.primers.find((p: any) => p.id === primer.id) !== undefined) {
                                return true;
                              }
                              return false;
                            }) !== undefined ? true : false
                          } onClick={() => _deletePrimer(primer.id)} type='button' className='btn btn-link btn-sm p-0 m-0'>
                            <i className="fas fa-times text-danger me-2"></i> Remove "<strong>{primer.type}</strong>"
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  })}
                </tbody>
              </table>
            </>}

            <small className="text-muted">* Hover Type to see details</small>

          </div>
          {/* Primers tab */}

        </div>
      </div>{/* /.container */}

    </>}

    <Footer />
  </>;
}

export default Management;
