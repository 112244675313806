import React, { useEffect, useState } from 'react';
import './SequencesComparator.scss';
import * as helpers from '../../helpers';
import Navbar from '../../components/Navbar';
import { AuthInterceptor } from '../../AuthInterceptor';
import Loading from '../../components/Loading/Loading';
import Sequence from '../../components/Sequence/Sequence';

const API: string = helpers.API;

/*
function Sequence(props: any): any {
  const {
    sequence,
    sequenceComplete,
    referenceSequence,
    showAllBases,
    keyid,
    debug,
  } = props;

  const _isBaseMutation = (position: number, currentBase: string): boolean => {
    if (!sequenceComplete.isReference) {
      if (referenceSequence) {
        if (referenceSequence.sequence[position] && (referenceSequence.sequence[position].toUpperCase() !== currentBase.toUpperCase())) {
          return true;
        }
      }
    }
    return false;
  }

  // Display the sequence with the mutations marked
  return sequence.split('').map((base: string, i: number) => {
    return <span key={`${keyid}-sequence-base-${i}`} className="sequence-base" style={{ borderLeft: (i === 0) ? 'none' : '' }}>
      {(debug && sequenceComplete.isReference) && <><small className="text-muted">{i+1}<br/></small></>}
      <span style={{ backgroundColor: _isBaseMutation(i, base.toUpperCase()) ? 'red' : 'transparent', color: _isBaseMutation(i, base.toUpperCase()) ? 'white' : 'black' }}>

        Always show the letter for the reference sequence
        {sequenceComplete.isReference && base.toUpperCase()}

        {!sequenceComplete.isReference && <>
          {_isBaseMutation(i, base.toUpperCase()) ? base.toUpperCase() : 
            showAllBases ? base.toUpperCase() : <>.</>}
        </>}
      </span>
      <div className="vertical-separador"></div>
    </span>
  });
}
*/

function SequencesComparator() {
  const [loading, setLoading] = useState<boolean>(false);

  const [listSequences, setListSequences] = useState<any>([]);
  const [listSequencesGrouped, setListSequencesGrouped] = useState<any>([]);

  const [selectedSequences, setSelectedSequences] = useState<any>([]);
  const [sequencesToCompare, setSequencesToCompare] = useState<any>([]);

  const [referenceSequence, setReferenceSequence] = useState<any>(null);

  const [showAllBases, setShowAllBases] = useState<boolean>(false);
  const [showIndex, setShowIndex] = useState<boolean>(false);

  async function _getSequencesListFromDB(): Promise<void> {
    await fetch(`${API}/sequences`)
      .then((response: any) => response.json())
      .then((data: any) => {
        for (let i=0; i<data.sequences.length; i++) {
          data.sequences[i] = {
            isReference: false,
            ...data.sequences[i],
          }
        };
        setListSequences(data.sequences);
        setLoading(false);

        // Group the sequences by the fasta_id
        const sequencesGrouped: any = data.sequences.reduce((r: any, a: any) => {
          r[a.fasta_id] = [...r[a.fasta_id] || [], a];
          return r;
        }, {});

        // For each group, add fasta_name=item[0].fasta_name
        const sequencesGroupedWithFastaName: any = [];
        for (const [key, value] of Object.entries(sequencesGrouped)) {
          const fastaName: string = sequencesGrouped[key][0].fasta_name || "No name";
          sequencesGroupedWithFastaName.push({
            fasta_id: key,
            fasta_name: fastaName,
            sequences: value,
          });
        }
        setListSequencesGrouped(sequencesGroupedWithFastaName);
      });
  }

  function _compareSelectedSequences(): void {
    if (selectedSequences.length >= 2) {
      setSequencesToCompare(selectedSequences);
    }
  }

  function _setReferenceSequence(sequenceId: number): void {
    const newSequenceRef: any = sequencesToCompare.find((sequence: any) => +sequence.id === +sequenceId);
    newSequenceRef.isReference = true;
    setReferenceSequence(newSequenceRef);

    sequencesToCompare.forEach((sequence: any) => {
      if (+sequence.id !== +sequenceId) {
        sequence.isReference = false;
      }
    });
    sequencesToCompare.sort((a: any, b: any) => (a.isReference) ? -1 : 1);
    setSequencesToCompare(sequencesToCompare);
  }

  useEffect(() => {
    AuthInterceptor();
    setLoading(true);
    _getSequencesListFromDB();
  }, []);

  return <>
    <Navbar />

    {loading && <Loading />}

    {!loading && <>

      <div className='container mb-5'>

        {listSequences && <>
          <div className="form-group mb-3">
            <div className="mb-3">
              <strong>Choose 2 or more sequences to compare</strong>
            </div>

            {listSequencesGrouped && <>
              {listSequencesGrouped.map((group: any) => {
                return (
                  <React.Fragment key={`group-${group.fasta_id}`}>
                    <div className="mb-3">
                      <div className="mb-3">
                        <strong>{group.fasta_name}</strong>
                      </div>
                      {group.sequences.map((sequence: any) => {
                        const isDifferentLength: boolean = selectedSequences.length > 0 && (sequence.sequence.length !== selectedSequences[0]?.sequence.length);
                        return (
                          <React.Fragment key={`sequence-${sequence.id}`}>
                            <div className="form-group mb-2 ms-5">
                              <label>
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  disabled={isDifferentLength}
                                  onChange={(e: any) => {
                                    const sequenceId: number = e.target.value;
                                    const sequence: any = listSequences.find((sequence: any) => +sequence.id === +sequenceId);
                                    if (e.target.checked) {
                                      setSelectedSequences([...selectedSequences, sequence]);
                                    } else {
                                      setSelectedSequences(selectedSequences.filter((selectedSequence: any) => +selectedSequence.id !== +sequenceId));
                                    }
                                  }}
                                  value={sequence.id}
                                />
                                <span className={`ms-2 ${isDifferentLength ? 'text-muted opacity-50' : ''}`}>
                                  {sequence.description}
                                </span>
                              </label>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </React.Fragment>
                )
              })}
            </>}
          </div>

          <button
            onClick={() => _compareSelectedSequences()}
            type="button"
            disabled={selectedSequences.length < 2}
            className='btn btn-primary'>Compare {selectedSequences.length} sequences</button>

          {(sequencesToCompare.length > 0) && <>
            <div className="mt-4 mb-3">
              <strong>View</strong>
            </div>

            <div className="row mt-1 mb-4">
              <div className="col-md-3">
                <label>
                  <input
                    type="checkbox"
                    onClick={() => setShowAllBases(!showAllBases)}
                  /> Show all bases
                </label>
              </div>
            </div>
          </>}

          {(selectedSequences.length > 0) && <>
            {(sequencesToCompare.length >= 2 && !referenceSequence) && <>
              <div className="mt-3 mb-3">
                <small className="text-muted">
                  Click at <span className="badge bg-secondary opacity-50">#</span> to set as reference
                </small>
              </div>
            </>}
          </>}
        </>}
      </div>{/* /.container */}

      <div className='container-fluid mb-5'>
        <div className="viewer-grid">

          {sequencesToCompare.map((item: any, i: number) => {
            return <React.Fragment key={`sequence-${item.id}`}>
              <div className="row align-items-end">
                <div className="col-2" title={item.description}>
                  {item.isReference ?
                    <span className="cursor-pointer me-2 badge bg-primary">#</span> : (
                    <span
                      className="cursor-pointer me-2 badge bg-secondary opacity-50"
                      onClick={() => _setReferenceSequence(item.id)}>#</span>
                  )} {item.description.substring(0, 15)}...
                </div>
                <div className="col-10">
                  <div className="sequence">

                    {referenceSequence && <>
                      <Sequence
                        keyId={`Sequence-item-${item.id}`}
                        sequence={item.sequence}
                        showPositions={i === 0 ? true : false}
                        showAllBases={showAllBases}
                        isReference={sequencesToCompare.find((item: any) => item.id === referenceSequence.id).id ? true : false}
                        referenceSequence={sequencesToCompare.find((item: any) => item.id === referenceSequence.id)?.sequence} />
                      </>}

                  </div>
                </div>
              </div>
            </React.Fragment>
          })}

        </div>{/* /.viewer-grid */}
      </div>{/* /.container-fluid */}

    </>}
  </>;
}

export default SequencesComparator;
