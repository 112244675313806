import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { AuthInterceptor } from "../../AuthInterceptor";
import { API } from "../../helpers";
import Loading from "../../components/Loading/Loading";
import React from "react";

function MultipleComparison() {

    /**
     * CORRECT DISPLAY:
     * 
     * Sequence 1 (Ref): -----------------------------------------------------------------
     * Sequence 2:       --------M-----------------------------------------------M--------
     * Sequence N:       -------------------M-------------M-------------------------------
     * 
     * Primerset 1: --------  ---M----  ----M---
     * Primerset 2:     --------      ------M-
     * Primerset N: --------  ---M----  ----M---
     * 
     * Finding mutations: select one sequence as reference and find mutations in others + primers
     * 
     * WRONG DISPLAY:
     * 
     * Sequence 1: -----------------------------------------------------------------
     * Primerset 1: --------  --------  --------
     * 
     * Sequence 2: -----------------------------------------------------------------
     * Primerset 2:     --------      --------
     * 
     * Sequence N: -----------------------------------------------------------------
     * Primerset N: --------  --------  --------
     */

    const [loading, setLoading] = useState<boolean>(false);
    const [listSequences, setListSequences] = useState<any>([]);
    const [listSequencesGrouped, setListSequencesGrouped] = useState<any>([]);
    const [selectedSequences, setSelectedSequences] = useState<any>([]);
    const [listPrimersets, setListPrimersets] = useState<any>([]);
    // const [selectedSequence, setSelectedSequence] = useState<any>(null);
    // const [primersetsInView, setPrimersetsInView] = useState<any>([]);
    // const [primersetListValue, setPrimersetListValue] = useState<any>('');
    // const [primersetColors, setPrimersetColors] = useState<any>([]);

    async function _getSequencesListFromDB(): Promise<void> {
        await fetch(`${API}/sequences`)
          .then((response: any) => response.json())
          .then((data: any) => {
            setListSequences(data.sequences);
            setLoading(false);

            // Group the sequences by the fasta_id
            const sequencesGrouped: any = data.sequences.reduce((r: any, a: any) => {
                r[a.fasta_id] = [...r[a.fasta_id] || [], a];
                return r;
            }, {});
    
            // For each group, add fasta_name=item[0].fasta_name
            const sequencesGroupedWithFastaName: any = [];
            for (const [key, value] of Object.entries(sequencesGrouped)) {
                const fastaName: string = sequencesGrouped[key][0].fasta_name || "No name";
                sequencesGroupedWithFastaName.push({
                    fasta_id: key,
                    fasta_name: fastaName,
                    sequences: value,
                });
            }
            setListSequencesGrouped(sequencesGroupedWithFastaName);
        });
    }

    async function _getPrimersetsListFromDB(): Promise<void> {
        await fetch(`${API}/primersets`)
          .then((response: any) => response.json())
          .then((data: any) => {
            setListPrimersets(data.primersets);
            setLoading(false);
        });
    }

    useEffect(() => {
        AuthInterceptor();
        setLoading(true);
        _getSequencesListFromDB();
        _getPrimersetsListFromDB();
    }, []);

    return <>
        <Navbar />

        {loading && <Loading />}

        {!loading && <>

            <div className="container">
                <h1>Multiple Comparison View</h1>


                {listSequences && <>
                    <div className="form-group mb-3">
                        <div className="mb-3">
                            <strong>Choose 1 or more sequences to compare</strong>
                        </div>

                        {listSequencesGrouped && <>
                            {listSequencesGrouped.map((group: any) => {
                                return (
                                <React.Fragment key={`group-${group.fasta_id}`}>
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            <strong>{group.fasta_name}</strong>
                                        </div>
                                        {group.sequences.map((sequence: any) => {
                                            const isDifferentLength: boolean = selectedSequences.length > 0 && (sequence.sequence.length !== selectedSequences[0]?.sequence.length);
                                            return (
                                                <React.Fragment key={`sequence-${sequence.id}`}>
                                                    <div className="form-group mb-2 ms-5">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                className="mr-2"
                                                                disabled={isDifferentLength}
                                                                onChange={(e: any) => {
                                                                    const sequenceId: number = e.target.value;
                                                                    const sequence: any = listSequences.find((sequence: any) => +sequence.id === +sequenceId);
                                                                    if (e.target.checked) {
                                                                        setSelectedSequences([...selectedSequences, sequence]);
                                                                    } else {
                                                                        setSelectedSequences(selectedSequences.filter((selectedSequence: any) => +selectedSequence.id !== +sequenceId));
                                                                    }
                                                                }}
                                                                value={sequence.id}
                                                            />
                                                                <span className={`ms-2 ${isDifferentLength ? 'text-muted opacity-50' : ''}`}>
                                                                    {sequence.description}
                                                                </span>
                                                        </label>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </React.Fragment>
                                )
                            })}
                        </>}

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />


                        <div className="form-group mb-3">
                            <div className="row">
                                <div className="col-8">
                                    <label>Select a primerset to add it to the sequence below</label>
                                    <select
                                        // disabled={!selectedSequence || loading}
                                        // value={primersetListValue}
                                        className='form-control'
                                        onChange={(e: any) => {
                                            // e.target.value && setPrimersetsInView([ ...primersetsInView, listPrimersets.find((item: any) => item.id === +e.target.value) ])
                                            // e.target.value && setPrimersetListValue('');
                                        }}>
                                        <option value=''>-</option>

                                        {/* <optgroup label="Matched Primersets" key={'matched-primersets-group'}> */}
                                            {listPrimersets.map((primerset: any, i: number) => {
                                            return (
                                                <option
                                                key={`Primersets-matched-${primerset.id}`}
                                                // disabled={primersetsInView.find((item: any) => item.id === +primerset.id)}
                                                value={primerset.id}>{primerset.name} ({primerset.primers.length} primers: {primerset.primers.map((item: any) => item.type).join(', ')})
                                                </option>
                                            )})}
                                        {/* </optgroup> */}

                                        {/*
                                        <optgroup label="Matched Primersets" key={'matched-primersets-group'}>
                                            {listPrimersets.map((primerset: any, i: number) => {
                                            return (+primerset.match_sequence_id === +selectedSequence.id) && 
                                                <option
                                                key={`Primersets-matched-${primerset.id}`}
                                                // disabled={primersetsInView.find((item: any) => item.id === +primerset.id)}
                                                value={primerset.id}>{primerset.name} ({primerset.primers.length} primers: {primerset.primers.map((item: any) => item.type).join(', ')})
                                                </option>
                                            })}
                                        </optgroup>
                                        <optgroup label="Unmatched Primersets" key={'unmatched-primersets-group'}>
                                            {listPrimersets.map((primerset: any, i: number) => {
                                            return (+primerset.match_sequence_id !== +selectedSequence.id) && 
                                                <option
                                                key={`Primersets-unmatched-${primerset.id}`}
                                                // disabled={primersetsInView.find((item: any) => item.id === +primerset.id)}
                                                value={primerset.id}>{primerset.name} ({primerset.primers.length} primers: {primerset.primers.map((item: any) => item.type).join(', ')})
                                                </option>
                                            })}
                                        </optgroup>
                                        */}

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>

        </>}
    </>;
}

export default MultipleComparison;